// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abnt-js": () => import("./../../src/pages/abnt.js" /* webpackChunkName: "component---src-pages-abnt-js" */),
  "component---src-pages-agro-js": () => import("./../../src/pages/agro.js" /* webpackChunkName: "component---src-pages-agro-js" */),
  "component---src-pages-equipe-js": () => import("./../../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-escritorio-js": () => import("./../../src/pages/escritorio.js" /* webpackChunkName: "component---src-pages-escritorio-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industria-comercio-js": () => import("./../../src/pages/industria-comercio.js" /* webpackChunkName: "component---src-pages-industria-comercio-js" */),
  "component---src-pages-lgpd-js": () => import("./../../src/pages/lgpd.js" /* webpackChunkName: "component---src-pages-lgpd-js" */),
  "component---src-pages-noticia-js": () => import("./../../src/pages/noticia.js" /* webpackChunkName: "component---src-pages-noticia-js" */),
  "component---src-pages-noticias-js": () => import("./../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-privacidade-js": () => import("./../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-saude-js": () => import("./../../src/pages/saude.js" /* webpackChunkName: "component---src-pages-saude-js" */),
  "component---src-pages-unidades-js": () => import("./../../src/pages/unidades.js" /* webpackChunkName: "component---src-pages-unidades-js" */)
}

